import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/Login";
import Dashboard from "./Dashboard";
function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/dashboard"  element={<Dashboard/>} ></Route>
    </Routes>
  );
}

export default MainRoutes;

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import { editcri, editlow, editmaj, editmid } from "../Redux/action";
function Edit({ data, edit, i }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [text, settext] = useState("");
  let dispatch = useDispatch();
  let { lowar, Mediumar, Majorar, Criticalar } = useSelector(
    (state) => state.LoginReducer
  );
  let handleChange = () => {
    if (data == "low") {
      editlow(lowar, i, dispatch, text);
      alert("Edit Succsfully");
    } else if (data == "critical") {
      editcri(Criticalar, i, dispatch, text);
      alert("Edit Succsfully");
    } else if (data == "major") {
      editmaj(Majorar, i, dispatch, text);
      alert("Edit Succsfully");
    } else if (data == "medium") {
      editmid(Mediumar, i, dispatch, text);
      alert("Edit Succsfully");
    }
  };
  return (
    <>
      <Text onClick={onOpen} cursor="pointer">
        <FaRegEdit />
      </Text>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Edit"
              onChange={(e) => settext(e.target.value)}
            ></Input>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="outline"
              mr="10px"
              //   onClick={() => addlow(lowar, dispatch, text)}
              onClick={handleChange}
            >
              Change it
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Edit;

import { Center, Grid, GridItem, Text, Button } from "@chakra-ui/react";
import React from "react";
import Modol from "./Modol";
import Major from "./Major";
import Medium from "./Medium";
import Low from "./Low";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import {
  handleDelCri,
  handleDellow,
  handleDelmaj,
  handleDelmid,
} from "../Redux/action";
import Edit from "./Edit";

function Dashboard() {
  let { lowar, Mediumar, Majorar, Criticalar } = useSelector(
    (state) => state.LoginReducer
  );
  let dispatch = useDispatch();
  return (
    <>
      <Text>
        <Text fontSize={"25px"} textAlign={"center"} m="20px" mb="30px">
          BUG TRACKER
        </Text>
        <Grid gridTemplateColumns={"repeat(4,1fr)"} gap="25px" w="90%" m="auto">
          <GridItem>
            <Modol />
            <Text bg="#f65251" mt="20px" p="13px" borderRadius={"7px"}>
              Critical Serverity
            </Text>
            {Criticalar &&
              Criticalar.map((el, i) => {
                return (
                  <>
                    <Text
                      bg="#eb4554"
                      mt="20px"
                      p="13px"
                      borderRadius={"7px"}
                      position={"relative"}
                      key={i}
                    >
                      <Text
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        {el}
                        <Text display={"flex"} gap="10px">
                          <Text
                            onClick={() =>
                              handleDelCri(Criticalar, i, dispatch)
                            }
                            cursor={"pointer"}
                          >
                            <RiDeleteBin5Line />
                          </Text>
                          <Text>
                            <Edit data="critical" edit={el} i={i} />
                          </Text>
                        </Text>
                      </Text>
                    </Text>
                  </>
                );
              })}
          </GridItem>
          <GridItem>
            <Major />
            <Text bg="#f4b452" mt="20px" p="13px" borderRadius={"7px"}>
              Major Serverity
            </Text>
            {Majorar &&
              Majorar.map((el, i) => {
                return (
                  <Text
                    bg="#e1ad26"
                    mt="20px"
                    p="13px"
                    borderRadius={"7px"}
                    key={i}
                  >
                    <Text
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      {el}
                      <Text display={"flex"} gap="10px">
                        <Text
                          onClick={() => handleDelmaj(Majorar, i, dispatch)}
                          cursor={"pointer"}
                        >
                          <RiDeleteBin5Line />
                        </Text>
                        <Text>
                          <Edit data="major" edit={el} i={i} />
                        </Text>
                      </Text>
                    </Text>
                  </Text>
                );
              })}
          </GridItem>
          <GridItem>
            <Medium />
            <Text bg="#5f55d0" mt="20px" p="13px" borderRadius={"7px"}>
              Medium Serverity
            </Text>
            {Mediumar &&
              Mediumar.map((el, i) => {
                return (
                  <Text
                    bg="#3956bc"
                    mt="20px"
                    p="13px"
                    borderRadius={"7px"}
                    key={i}
                  >
                    <Text
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      {el}
                      <Text display={"flex"} gap="10px">
                        <Text
                          onClick={() => handleDelmid(Mediumar, i, dispatch)}
                          cursor={"pointer"}
                        >
                          <RiDeleteBin5Line />
                        </Text>
                        <Text>
                          <Edit data="medium" edit={el} i={i}/>
                        </Text>
                      </Text>
                    </Text>
                  </Text>
                );
              })}
          </GridItem>
          <GridItem>
            <Low />
            <Text bg="#277c53" mt="20px" p="13px" borderRadius={"7px"}>
              Low Serverity
            </Text>
            {lowar &&
              lowar.map((el, i) => {
                return (
                  <Text
                    bg="#44700f"
                    mt="20px"
                    p="13px"
                    borderRadius={"7px"}
                    key={i}
                  >
                    <Text
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      {el}
                      <Text display={"flex"} gap="10px">
                        <Text
                          onClick={() => handleDellow(lowar, i, dispatch)}
                          cursor={"pointer"}
                        >
                          <RiDeleteBin5Line />
                        </Text>
                        <Text>
                          <Edit data="low" edit={el} i={i}/>
                        </Text>
                      </Text>
                    </Text>
                  </Text>
                );
              })}
          </GridItem>
        </Grid>
      </Text>
    </>
  );
}

export default Dashboard;

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addcritical } from "../Redux/action";
function Modol() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [text, settext] = useState("");
  let dispatch = useDispatch();
  let { lowar, Mediumar, Majorar, Criticalar } = useSelector(
    (state) => state.LoginReducer
  );
  return (
    <>
      <Button onClick={onOpen} colorScheme="teal" variant="solid">
        Report Bug
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Bug Name"
              onChange={(e) => settext(e.target.value)}
            ></Input>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="teal"
              variant="outline"
              mr="10px"
              onClick={() => addcritical(Criticalar,dispatch, text)}
            >
              Add it
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Modol;

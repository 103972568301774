export let addlow = (data, dispatch, text) => {
  if (data.length >= 5) {
    alert("You can add onlt five cards at a time");
    return;
  }
  dispatch({ type: "ADD_LOW", payload: text });
};
export let addmedium = (data, dispatch, text) => {
  if (data.length >= 5) {
    alert("You can add onlt five cards at a time");
    return;
  }
  dispatch({ type: "ADD_MEDIUM", payload: text });
};
export let addmajor = (data, dispatch, text) => {
  if (data.length >= 5) {
    alert("You can add onlt five cards at a time");
    return;
  }
  dispatch({ type: "ADD_MAJOR", payload: text });
};
export let addcritical = (data, dispatch, text) => {
  if (data.length >= 5) {
    alert("You can add onlt five cards at a time");
    return;
  }
  dispatch({ type: "ADD_CRITICAL", payload: text });
};
export let handleDelCri = (data, i, dispatch) => {
  let arr = data.filter((el, index) => {
    if (i != index) {
      return el;
    }
  });
  dispatch({ type: "DEL_CRI", payload: arr });
};
export let handleDellow = (data, i, dispatch) => {
  let arr = data.filter((el, index) => {
    if (i != index) {
      return el;
    }
  });
  dispatch({ type: "DEL_LOW", payload: arr });
};
export let handleDelmid = (data, i, dispatch) => {
  let arr = data.filter((el, index) => {
    if (i != index) {
      return el;
    }
  });
  dispatch({ type: "DEL_MID", payload: arr });
};
export let handleDelmaj = (data, i, dispatch) => {
  let arr = data.filter((el, index) => {
    if (i != index) {
      return el;
    }
  });
  dispatch({ type: "DEL_MAJ", payload: arr });
};

export let editlow = (data, i, dispatch, text) => {
  let arr = data.map((el, index) => {
    if (index == i) {
      el = text;
    }
    return el;
  });
  dispatch({ type: "EDIT_LOW", payload: arr });
};
export let editmid = (data, i, dispatch, text) => {
  let arr = data.map((el, index) => {
    if (index == i) {
      el = text;
    }
    return el;
  });
  dispatch({ type: "EDIT_MID", payload: arr });
};
export let editcri = (data, i, dispatch, text) => {
  let arr = data.map((el, index) => {
    if (index == i) {
      el = text;
    }
    return el;
  });
  dispatch({ type: "EDIT_CRI", payload: arr });
};
export let editmaj = (data, i, dispatch, text) => {
  let arr = data.map((el, index) => {
    if (index == i) {
      el = text;
    }
    return el;
  });
  dispatch({ type: "EDIT_MAJ", payload: arr });
};


let initial = {
  lowar: [],
  Mediumar: [],
  Majorar: [],
  Criticalar: [],
};

export let reducer = (state = initial, { type, payload }) => {
  switch (type) {
    case "ADD_LOW": {
      return { ...state, lowar: [...state.lowar, payload] };
    }
    case "ADD_MEDIUM": {
      return { ...state, Mediumar: [...state.Mediumar, payload] };
    }
    case "ADD_MAJOR": {
      return { ...state, Majorar: [...state.Majorar, payload] };
    }
    case "ADD_CRITICAL": {
      return { ...state, Criticalar: [...state.Criticalar, payload] };
    }
    case "DEL_CRI": {
      return { ...state, Criticalar: payload };
    }
    case "DEL_LOW": {
      return { ...state, lowar: payload };
    }
    case "DEL_MID": {
        return { ...state, Mediumar: payload };
    }
    case "DEL_MAJ": {
        return { ...state, Majorar: payload };
    }
    case "EDIT_LOW": {
      return { ...state, lowar: payload };
    }
    case "EDIT_MID": {
      return { ...state, Mediumar: payload };
    }
    case "EDIT_CRI": {
      return { ...state, Criticalar: payload };
    }
    case "EDIT_MAJ": {
      return { ...state, Majorar: payload };
    }
    default:
      return state;
  }
};
